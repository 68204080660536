import config from "./config";
import Auth from "./AWSCognito/Auth";

export default class Fetcher {
  static async fetchAmyyon(url, method, body) {
    let token = "";
    await Auth.currentAuthenticatedUser().then(user => {
      token = user.signInUserSession.accessToken.getJwtToken();
    });
    return fetch(config.url.BASE_AMYYON_API_URL + url, {
      method: method,
      headers: {
        Accept: "application/json",
        AccessToken: token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }
}
