import React, { Component } from "react";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import Catalogs from "./Catalogs";
import ProductCardInfo from "./ProductCardInfo";
import Preview from "./Preview";
import Confirmation from "./Confirmation";

class CreateProduct extends Component {
  state = {
    activeStep: 0,
    firstStep: null,
    secondStep: null,
    thirdStep: null,
    fourthStep: null,
    selectedProduct: ""
  };

  handleUpdateStepper = (model, step) => {
    if (step === 1) {
      this.setState({ firstStep: model });
      this.handleNext();
      this.getStepContent(1);
    } else if (step === 2) {
      this.setState({ secondStep: model });
    } else if (step === 3) {
      this.setState({ thirdStep: model });
    }
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Catalogs
            props={this.props}
            onUpdateStepperState={this.handleUpdateStepper.bind(this)}
          />
        );
      case 1:
        return (
          <ProductCardInfo
            ref="child"
            props={this.props}
            selectedProduct={this.state.firstStep}
            onUpdateStepperState={this.handleUpdateStepper.bind(this)}
          />
        );
      case 2:
        return <Preview entireInfoPreview={this.state.fourthStep} />;
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    let isFormValid = false;
    if (this.state.activeStep === 1) {
      isFormValid = this.refs.child.isFormValid();
      if (isFormValid) {
        const modelThirdStep = {
          ProductName: this.state.firstStep.productDescription,
          ProductId: this.state.firstStep.productId,
          StartDate: this.state.secondStep.StartDate,
          EndDate: this.state.secondStep.EndDate,
          CenterZone: this.state.secondStep.CenterZone,
          StarValue: this.state.secondStep.StarValue,
          Price: this.state.thirdStep.Price,
          Email: this.state.thirdStep.Email,
          Reference: this.state.thirdStep.Reference,
          DepartmentCode: this.state.thirdStep.DepartmentCode,
          Salutation: this.state.thirdStep.Salutation,
          Country: this.state.thirdStep.Country,
          LastName: this.state.thirdStep.LastName,
          Inserts: this.state.thirdStep.Inserts,
          Initials: this.state.thirdStep.Initials,
          Postcode: this.state.thirdStep.Postcode,
          HouseNo: this.state.thirdStep.HouseNo,
          Residence: this.state.thirdStep.Residence,
          Street: this.state.thirdStep.Street,
          ChipCardNumber: this.state.thirdStep.ChipCardNumber
        };
        this.setState({ fourthStep: modelThirdStep });
      } else {
        return;
      }
    }
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  render() {
    const steps = ["Select product", "Product info", "Preview"];
    const { activeStep } = this.state;

    return (
      <div>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Confirmation
                props={this.props}
                placeOrder={this.state.fourthStep}
              />
            </div>
          ) : (
            <div>
              {this.getStepContent(activeStep)}
              {activeStep !== 0 && (
                <div>
                  <Button disabled={activeStep === 0} onClick={this.handleBack}>
                    Terug
                  </Button>
                  {this.state.secondStep !== null &&
                    this.state.secondStep.validCardNumber && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleNext()}
                      >
                        {activeStep === steps.length - 1
                          ? "Bestellen"
                          : "Volgende stap"}
                      </Button>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreateProduct;
