import React, { Component } from "react";

export default class Confirmation extends Component {
  render() {
    return (
      <div className="page-container">
        <h1>Terugstorten</h1>
        <div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Kaartnummer</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.props.cardProduct.engravedCardId}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Product</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.props.cardProduct.cardProductId}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              Productstatus
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.props.cardProduct.productStatus}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Prijs</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {new Intl.NumberFormat("nl", {
                style: "currency",
                currency: "EUR"
              }).format(this.props.cardProduct.price)}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              Gewenste beeindigingsdatum
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.props.stepperState.date
                ? new Intl.DateTimeFormat("nl").format(
                    new Date(this.props.stepperState.date)
                  )
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              Restitutiebedrag
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {new Intl.NumberFormat("nl", {
                style: "currency",
                currency: "EUR"
              }).format(this.props.cardProduct.refund)}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              E-mailadres kaarthouder
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.props.stepperState.mail}
            </div>
          </div>
        </div>
        <br />
        <p>
          Staat het product nog niet op de OV-chipkaart? Dan hoeft u niets te
          doen.
        </p>
        <p>
          Als het product nog niet op de OV-chipkaart staat (zie productstatus),
          dan wordt het pas stopgezet nadat u het van de kaart afhaalt. Dat kan
          bij ca. 3000 oplaad of ophaalpunten (kies indien nodig voor de optie
          "ophalen bestelling").
        </p>
        <p>
          Bovenstaand restitutiebedrag is indicatief en gaat ervan uit, dat u
          het product vandaag van de kaart haart. Doet u dit later, krijgt u
          minder geld terug.
        </p>
      </div>
    );
  }
}
