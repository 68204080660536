const dev = {
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_unyJ6oELM",
    APP_CLIENT_ID: "4u4scnf0sg7041h2m8jgdab38u"
  },
  url: {
    BASE_AUTH_API_URL: "https://b2b-auth.stage02.netromsoftware.ro/api/",
    BASE_AMYYON_API_URL: "https://b2b-amyyon.stage02.netromsoftware.ro/api/"
  }
};

const acceptance = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_TwYpMXIJH",
    APP_CLIENT_ID: "4u4scnf0sg7041h2m8jgdab38u",
    IDENTITY_POOL_ID: "us-east-1:f4c754b4-24f0-4754-8596-30afedece1fc"
  }
};

const config = process.env.REACT_APP_STAGE === "acceptance" ? acceptance : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
