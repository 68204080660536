import React, { Component } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
  Icon,
  Button
} from "@material-ui/core";
import Fetcher from "../../Fetcher";
import ProductInfo from "./ProductInfo";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

export default class ProductCardInfo extends Component {
  constructor() {
    super();
    this.state = {
      CenterZone: "",
      StarValue: "",
      StartDate: Date.now(),
      EndDate: Date.now(),
      ChipCardNumber: "",
      ChipCardExpirationDate: "",
      Price: "",
      validCardNumber: false,
      isCardNumberChecked: false,
      errors: {}
    };
  }

  isFormValid = () => {
    this.checkFormValidation();
    if (this.state.errors !== null) {
      return false;
    }

    return this.refs.child.isFormValid();
  };

  handleChange = event => {
    this.setState({ errors: null });
    this.setState({ [event.target.name]: event.target.value });
    this.props.onUpdateStepperState(this.state, 2);
  };

  handleDateChange = date => {
    this.setState({ errors: null });
    this.setState({ StartDate: date });
    this.props.onUpdateStepperState(this.state, 2);
  };

  handleCardNumberChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ isCardNumberChecked: false });
    this.props.onUpdateStepperState(this.state, 2);
  };

  checkFormValidation = () => {
    if (!this.state.CenterZone) {
      this.setState({ errors: { CenterZone: true } });
    }

    if (!this.state.StarValue) {
      this.setState({ errors: { StarValue: true } });
    }

    if (!this.state.ChipCardNumber) {
      this.setState({ errors: { ChipCardNumber: true } });
    }
  };

  handleCheckCardNumber = () => {
    this.checkFormValidation();
    if (this.state.errors !== null) {
      return;
    }

    this.props.props.isLoadingMode(true);
    const that = this;
    const checkCardNumber = {
      CardNumber: this.state.ChipCardNumber,
      ProductId: this.props.selectedProduct.productId
    };
    Fetcher.fetchAmyyon("products/CheckCardNumber", "POST", checkCardNumber)
      .then(response => response.json())
      .then(
        response => {
          if (response.cardResponse.cardStatus === 0) {
            that.setState({
              ChipCardExpirationDate: response.cardResponse.cardEndDate
            });
            that.setState({
              Price: response.price
            });
            that.setState({ isCardNumberChecked: true });
            that.setState({ validCardNumber: true });
            that.props.props.isLoadingMode(false);
            this.props.onUpdateStepperState(this.state, 2);
          } else {
            console.log("card number not valid");
            that.setState({ validCardNumber: false });
            that.setState({ isCardNumberChecked: true });
            that.props.props.isLoadingMode(false);
            this.props.onUpdateStepperState(this.state, 2);
          }
        },
        error => {
          console.log("card number not valid");
          that.setState({ validCardNumber: false });
          that.setState({ isCardNumberChecked: true });
          that.props.props.isLoadingMode(false);
          this.props.onUpdateStepperState(this.state, 2);
        }
      );
  };

  render() {
    return (
      <React.Fragment>
        <div className="info-container">
          <h2>{this.props.selectedProduct.productDescription}</h2>
          <h3>Uw bestelling</h3>
        </div>
        <FormControl required className="input-form-control">
          <InputLabel htmlFor="CenterZone">Centrumzone</InputLabel>
          <Select
            required
            inputProps={{
              name: "CenterZone",
              id: "CenterZone"
            }}
            value={this.state.CenterZone}
            onChange={this.handleChange}
          >
            {this.props.selectedProduct.zones !== null &&
              this.props.selectedProduct.zones.split(",").map(function(zone) {
                return (
                  <MenuItem key={zone} value={zone}>
                    {zone}
                  </MenuItem>
                );
              }, this)}
          </Select>
          <Tooltip
            className="right-tooltip"
            title="De centrumzone is het centrum van uw reisgebied. Vanuit daar kunt u in alle richtingen reizen. Het is dus van belang dat u de centrumzone kiest die voor u het handigst is. In de lijst ziet u alle centrumzones die geldig zijn voor dit product. Kies een centrumzone + sterwaarde en bekijk uw reisgebied op de kaart.Lees meer over de centrumzone"
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
          {this.state.errors && this.state.errors.CenterZone && (
            <FormHelperText className="error">
              Selecteer centrumzone
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <InputLabel htmlFor="StarValue">Sterwaarde</InputLabel>
          <Select
            inputProps={{
              name: "StarValue",
              id: "StarValue"
            }}
            value={this.state.StarValue}
            onChange={this.handleChange}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </Select>
          <Tooltip
            className="right-tooltip"
            title="De sterwaarde geeft aan binnen hoeveel zones u kunt reizen. Dus hoe hoger 0uw sterwaarde, hoe groter de afstand waarover u kunt reizen. Kies een sterwaarde + centrumzone en bekijk uw reisgebied op de kaart.Lees meer over de sterwaarde"
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
          {this.state.errors && this.state.errors.StarValue && (
            <FormHelperText className="error">Field is required</FormHelperText>
          )}
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className="input-form-control">
            <DatePicker
              label="Ingangsdatum"
              value={this.state.StartDate}
              onChange={this.handleDateChange}
              animateYearScrolling
              format="MM/dd/yyyy"
            />
            <Tooltip
              className="right-tooltip"
              title="Kies de datum vanaf wanneer u wilt kunnen reizen met dit product."
            >
              <Icon color="primary">help</Icon>
            </Tooltip>
          </FormControl>
          <FormControl className="input-form-control">
            <DatePicker
              label="Geldig t/m"
              value={this.state.StartDate}
              onChange={this.handleDateChange}
              animateYearScrolling
              format="MM/dd/yyyy"
              disabled
            />
          </FormControl>
        </MuiPickersUtilsProvider>
        <div className="info-container">
          <h3>Persoonlijk OV-chipkaartnummer</h3>
          <p>
            Vul hieronder het OV-chipkaartnummer in waarvoor het product besteld
            wordt. Het product kan uitsluitend op een persoonlijke OV-chipkaart
            worden geplaatst.
          </p>
        </div>
        {!this.state.validCardNumber ? (
          <React.Fragment>
            <FormControl required className="input-form-control">
              <TextField
                label="OV-chipkaartnummer"
                value={this.state.ChipCardNumber}
                onChange={this.handleCardNumberChange}
                inputProps={{
                  name: "ChipCardNumber",
                  id: "ChipCardNumber"
                }}
                required
              />
              <Tooltip
                className="right-tooltip"
                title="Vul hier de 16 cijfers in van de OV-chipkaart waar u het product op wilt zetten. De juiste prijs wordt berekend aan de hand van uw OV-chipkaartnummer. U ziet het definitieve tarief als u klikt op ‘bestellen’ of ‘plaats in winkelwagen’. "
              >
                <Icon color="primary">help</Icon>
              </Tooltip>
              {!this.state.validCardNumber &&
                this.state.isCardNumberChecked && (
                  <FormHelperText>Card number is not valid</FormHelperText>
                )}
              {this.state.errors && this.state.errors.ChipCardNumber && (
                <FormHelperText className="error">
                  Verplicht veld
                </FormHelperText>
              )}
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleCheckCardNumber()}
              className="check-card-button"
            >
              Controleren
            </Button>
          </React.Fragment>
        ) : (
          <ProductInfo
            ref="child"
            props={{
              ChipCardNumber: this.state.ChipCardNumber,
              ChipCardExpirationDate: this.state.ChipCardExpirationDate,
              Price: this.state.Price
            }}
            onUpdateStepperState={this.props.onUpdateStepperState}
          />
        )}
      </React.Fragment>
    );
  }
}
