import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Routes from "./Routes";
import Auth from "./AWSCognito/Auth";
import "./App.css";

class App extends Component {
  state = {
    auth: true,
    anchorEl: null
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      isLoading: false
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentAuthenticatedUser().then(
        res => {
          this.userHasAuthenticated(true);
        },
        error => {
          console.log(error);
        }
      );
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };

  isLoadingMode = isLoading => {
    this.setState({ isLoading: isLoading });
  };

  handleLogout = async event => {
    Auth.signOut();

    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      isLoadingMode: this.isLoadingMode
    };

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      !this.state.isAuthenticating && (
        <div className="App">
          {this.state.isLoading && <LinearProgress />}
          <MuiThemeProvider>
            <div>
              {this.state.isAuthenticated && (
                <AppBar position="static">
                  <Toolbar>
                    <Typography
                      className="headerText"
                      variant="h6"
                      color="inherit"
                    >
                      Connexxion Zakelij - Grootgebruik
                    </Typography>
                    <div className="headerUserInfo">
                      <Typography
                        className="userName"
                        variant="h6"
                        color="inherit"
                      >
                        {Auth.getLoggedUsername()}
                      </Typography>
                      <IconButton
                        aria-owns={open ? "menu-appbar" : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                        className="userIconButton"
                      >
                        <AccountCircle />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        open={open}
                        onClose={this.handleClose}
                      >
                        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  </Toolbar>
                </AppBar>
              )}
            </div>
          </MuiThemeProvider>
          <div className="container page-container">
            <Routes childProps={childProps} />
          </div>
        </div>
      )
    );
  }
}

export default withRouter(App);
