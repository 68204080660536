import StorageHelper from "./StorageHelper";
import CognitoUserPool from "./CognitoUserPool";
import CognitoUser from "./CognitoUser";

export default class Auth {
  static _config;
  static _storage;
  static _storageSync;
  static userPool = null;
  static _storageSync;

  static configure(config) {
    if (!config) return this._config || {};
    const conf = Object.assign({}, this._config, config.Auth, config);
    this._config = conf;

    const { userPoolId, userPoolWebClientId } = this._config;

    this._storage = new StorageHelper().getStorage();

    this._storageSync = Promise.resolve();
    if (typeof this._storage["sync"] === "function") {
      this._storageSync = this._storage["sync"]();
    }

    if (userPoolId) {
      const userPoolData = {
        UserPoolId: userPoolId,
        ClientId: userPoolWebClientId
      };
      userPoolData.Storage = this._storage;

      this.userPool = new CognitoUserPool(userPoolData);
    }
  }

  static currentAuthenticatedUser() {
    let user = null;
    try {
      user = this.currentUserPoolUser();
    } catch (e) {}
    this.user = user;
    return this.user;
  }

  static getLoggedUsername() {
    return this.userPool.getCurrentUser()
      ? this.userPool.getCurrentUser().username
      : "";
  }

  static currentUserPoolUser() {
    if (!this.userPool) {
      return Promise.reject("No userPool");
    }
    const that = this;
    return new Promise((res, rej) => {
      this._storageSync
        .then(() => {
          const user = that.userPool.getCurrentUser();
          if (!user) {
            rej("No current user");
            return;
          }

          // refresh the session if the session expired.
          user.getSession((err, session) => {
            if (err) {
              rej(err);
              return;
            }

            // get user data from Cognito
            user.getUserData((err, data) => {
              if (err) {
                // Make sure the user is still valid
                if (
                  err.message === "User is disabled" ||
                  err.message === "User does not exist."
                ) {
                  rej(err);
                } else {
                  // the error may also be thrown when lack of permissions to get user info etc
                  // in that case we just bypass the error
                  res(user);
                }
                return;
              }
              res(user);
            });
          });
        })
        .catch(e => {
          return rej(e);
        });
    });
  }

  static signIn(info) {
    const user = this.createCognitoUser(info.username);
    user.authenticateUser(info);
  }

  static createCognitoUser(username) {
    const userData = {
      Username: username,
      Pool: this.userPool
    };
    userData.Storage = this._storage;

    const { authenticationFlowType } = this._config;

    const user = new CognitoUser(userData);
    if (authenticationFlowType) {
      user.setAuthenticationFlowType(authenticationFlowType);
    }
    return user;
  }

  static signOut() {
    if (this.userPool) {
      const user = this.userPool.getCurrentUser();
      if (user) {
        user.signOut();
      }
    }

    this.user = null;
  }
}
