import React, { Component } from "react";
import Fetcher from "../../Fetcher";

export default class Confirmation extends Component {
  componentDidMount() {
    this.props.props.isLoadingMode(true);
    const that = this;
    const placeOrder = {
      ProductId: this.props.placeOrder.ProductId,
      StartDate: new Date(this.props.placeOrder.StartDate),
      EndDate: new Date(this.props.placeOrder.EndDate),
      CenterZone: this.props.placeOrder.CenterZone,
      ChipCardNumber: this.props.placeOrder.ChipCardNumber
    };
    Fetcher.fetchAmyyon("products/PlaceOrder", "POST", placeOrder)
      .then(response => response.json())
      .then(
        response => {
          that.props.props.isLoadingMode(false);
        },
        error => {
          that.props.props.isLoadingMode(false);
        }
      );
  }
  render() {
    return (
      <React.Fragment>
        <div className="info-container">
          <h3>Bestelling geplaatst</h3>
          <p>
            Uw product is besteld. U kunt het product nu afhalen. Let op dat u
            pas kunt reizen nadat het product op de chipkaart geladen is. Biedt
            de chipkaart aan bij een van de ca. 300 oplaad en ophaalputen en
            druk op de knop ophalen bestelling.
          </p>
          <a className="link" href="#">
            > Bestel nog een {this.props.placeOrder.ProductName}
          </a>
          <br />
          <a className="link" href="/createproduct">
            > Bestel een ander product
          </a>
          <br />
          <a className="link" href="/product-overview">
            > Terug naad het overzicht
          </a>
          <br />
        </div>
      </React.Fragment>
    );
  }
}
