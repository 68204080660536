import CognitoAccessToken from "./CognitoAccessToken";
import CognitoIdToken from "./CognitoIdToken";
import CognitoRefreshToken from "./CognitoRefreshToken";
import CognitoUserSession from "./CognitoUserSession";
import StorageHelper from "./StorageHelper";
import config from "../config";

export default class CognitoUser {
  constructor(data) {
    if (data == null || data.Username == null || data.Pool == null) {
      throw new Error("Username and pool information are required.");
    }

    this.username = data.Username || "";
    this.pool = data.Pool;
    this.Session = null;

    this.client = data.Pool.client;

    this.signInUserSession = null;
    this.authenticationFlowType = "USER_SRP_AUTH";

    this.storage = data.Storage || new StorageHelper().getStorage();
  }

  setSignInUserSession(signInUserSession) {
    this.clearCachedTokens();
    this.signInUserSession = signInUserSession;
    this.cacheTokens();
  }

  getSignInUserSession() {
    return this.signInUserSession;
  }

  getUsername() {
    return this.username;
  }

  getSession(callback) {
    if (this.username == null) {
      return callback(
        new Error("Username is null. Cannot retrieve a new session"),
        null
      );
    }

    if (this.signInUserSession != null && this.signInUserSession.isValid()) {
      return callback(null, this.signInUserSession);
    }

    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}.${
      this.username
    }`;
    const idTokenKey = `${keyPrefix}.idToken`;
    const accessTokenKey = `${keyPrefix}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.clockDrift`;

    if (this.storage.getItem(idTokenKey)) {
      const idToken = new CognitoIdToken({
        IdToken: this.storage.getItem(idTokenKey)
      });
      const accessToken = new CognitoAccessToken({
        AccessToken: this.storage.getItem(accessTokenKey)
      });
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: this.storage.getItem(refreshTokenKey)
      });
      const clockDrift = parseInt(this.storage.getItem(clockDriftKey), 0) || 0;

      const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
        ClockDrift: clockDrift
      };
      const cachedSession = new CognitoUserSession(sessionData);
      if (cachedSession.isValid()) {
        this.signInUserSession = cachedSession;
        return callback(null, this.signInUserSession);
      }

      if (!refreshToken.getToken()) {
        return callback(
          new Error("Cannot retrieve a new session. Please authenticate."),
          null
        );
      }

      this.refreshSession(refreshToken, callback);
    } else {
      callback(
        new Error("Local storage is missing an ID Token, Please authenticate"),
        null
      );
    }

    return undefined;
  }

  refreshSession(refreshToken, callback) {
    const authParameters = {};
    authParameters.RefreshToken = refreshToken.getToken();
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;

    if (this.storage.getItem(lastUserKey)) {
      authParameters.Username = this.storage.getItem(lastUserKey);
    }

    fetch(config.url.BASE_AUTH_API_URL + "cognito/RefreshUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(authParameters)
    })
      .then(authResult => authResult.json())
      .then(
        authResult => {
          if (authResult) {
            const authenticationResult = authResult.user.result.sessionTokens;
            if (
              !Object.prototype.hasOwnProperty.call(
                authenticationResult,
                "RefreshToken"
              )
            ) {
              authenticationResult.refreshToken = refreshToken.getToken();
            }
            this.signInUserSession = this.getCognitoUserSession(
              authenticationResult
            );
            this.cacheTokens();
            return callback(null, this.signInUserSession);
          }
        },
        error => {
          this.clearCachedTokens();
        }
      );
    return undefined;
  }

  cacheTokens() {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}`;
    const idTokenKey = `${keyPrefix}.${this.username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${this.username}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.${this.username}.refreshToken`;
    const clockDriftKey = `${keyPrefix}.${this.username}.clockDrift`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;

    this.storage.setItem(
      idTokenKey,
      this.signInUserSession.getIdToken().getJwtToken()
    );
    this.storage.setItem(
      accessTokenKey,
      this.signInUserSession.getAccessToken().getJwtToken()
    );
    this.storage.setItem(
      refreshTokenKey,
      this.signInUserSession.getRefreshToken().getToken()
    );
    this.storage.setItem(
      clockDriftKey,
      `${this.signInUserSession.getClockDrift()}`
    );
    this.storage.setItem(lastUserKey, this.username);
  }

  cacheDeviceKeyAndPassword() {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}.${
      this.username
    }`;
    const deviceKeyKey = `${keyPrefix}.deviceKey`;
    const randomPasswordKey = `${keyPrefix}.randomPasswordKey`;
    const deviceGroupKeyKey = `${keyPrefix}.deviceGroupKey`;

    this.storage.setItem(deviceKeyKey, this.deviceKey);
    this.storage.setItem(randomPasswordKey, this.randomPassword);
    this.storage.setItem(deviceGroupKeyKey, this.deviceGroupKey);
  }

  getCachedDeviceKeyAndPassword() {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}.${
      this.username
    }`;
    const deviceKeyKey = `${keyPrefix}.deviceKey`;
    const randomPasswordKey = `${keyPrefix}.randomPasswordKey`;
    const deviceGroupKeyKey = `${keyPrefix}.deviceGroupKey`;

    if (this.storage.getItem(deviceKeyKey)) {
      this.deviceKey = this.storage.getItem(deviceKeyKey);
      this.randomPassword = this.storage.getItem(randomPasswordKey);
      this.deviceGroupKey = this.storage.getItem(deviceGroupKeyKey);
    }
  }

  clearCachedDeviceKeyAndPassword() {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}.${
      this.username
    }`;
    const deviceKeyKey = `${keyPrefix}.deviceKey`;
    const randomPasswordKey = `${keyPrefix}.randomPasswordKey`;
    const deviceGroupKeyKey = `${keyPrefix}.deviceGroupKey`;

    this.storage.removeItem(deviceKeyKey);
    this.storage.removeItem(randomPasswordKey);
    this.storage.removeItem(deviceGroupKeyKey);
  }

  clearCachedTokens() {
    const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}`;
    const idTokenKey = `${keyPrefix}.${this.username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${this.username}.accessToken`;
    const refreshTokenKey = `${keyPrefix}.${this.username}.refreshToken`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;
    const clockDriftKey = `${keyPrefix}.${this.username}.clockDrift`;

    this.storage.removeItem(idTokenKey);
    this.storage.removeItem(accessTokenKey);
    this.storage.removeItem(refreshTokenKey);
    this.storage.removeItem(lastUserKey);
    this.storage.removeItem(clockDriftKey);
  }

  getCognitoUserSession(authResult) {
    const idToken = new CognitoIdToken({ IdToken: authResult.idToken });
    const accessToken = new CognitoAccessToken({
      AccessToken: authResult.accessToken
    });
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: authResult.refreshToken
    });

    const sessionData = {
      IdToken: idToken,
      AccessToken: accessToken,
      RefreshToken: refreshToken
    };

    return new CognitoUserSession(sessionData);
  }

  signOut() {
    this.signInUserSession = null;
    this.clearCachedTokens();
  }

  getUserContextData() {
    const pool = this.pool;
    return pool.getUserContextData(this.username);
  }

  authenticateUser(info) {
    this.signInUserSession = this.getCognitoUserSession(info.tokens);
    this.cacheTokens();
  }

  getUserData(callback) {
    if (!(this.signInUserSession != null && this.signInUserSession.isValid())) {
      return callback(new Error("User is not authenticated"), null);
    }

    return callback(null, this.signInUserSession);
  }
}
