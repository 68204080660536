import React, { Component } from "react";
import TextField from "material-ui/TextField";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Auth from "../../AWSCognito/Auth";
import config from "../../config";
import "whatwg-fetch";
import { Typography, Button } from "@material-ui/core";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: ""
    };
  }

  render() {
    return (
      <MuiThemeProvider>
        <React.Fragment>
          <div className="login-form">
            <Typography>
              <div className="login-header">AANMELDEN</div>
            </Typography>
            <TextField
              hintText="Voer je e-mailadres in"
              floatingLabelText="E-mail"
              onChange={(event, newValue) =>
                this.setState({ username: newValue })
              }
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleLogin();
                }
              }}
            />
            <br />
            <TextField
              type="password"
              hintText="Voer uw wachtwoord in"
              floatingLabelText="Wachtwoord"
              onChange={(event, newValue) =>
                this.setState({ password: newValue })
              }
              onKeyPress={ev => {
                if (ev.key === "Enter") {
                  this.handleLogin();
                }
              }}
            />
            <br />
            <Button
              autoFocus={true}
              className="login-button"
              color="primary"
              variant="contained"
              onClick={async () => this.handleLogin()}
            >
              Log in
            </Button>
          </div>
        </React.Fragment>
      </MuiThemeProvider>
    );
  }

  handleLogin = async () => {
    const payload = {
      username: this.state.username,
      password: this.state.password
    };
    try {
      var that = this;
      await fetch(config.url.BASE_AUTH_API_URL + "cognito/SignIn", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(
          response => {
            that.props.userHasAuthenticated(true);
            Auth.signIn({
              username: response.user.username,
              tokens: response.user.sessionTokens
            });
            that.props.history.push("/product-overview");
          },
          error => {
            that.props.userHasAuthenticated(false);
            alert("This username doesn't exist");
          }
        );
    } catch (e) {
      alert(e.message);
    }
  };
}

export default Login;
