import React, { Component } from "react";
import { Button, Icon } from "@material-ui/core";

export default class ListCatalogs extends Component {
  render() {
    return (
      <div>
        {this.props.value.map(function(product) {
          return (
            <Button
              key={product.productId}
              className="catalog-button"
              color="primary"
              variant="contained"
              onClick={() => this.props.clickCatalog(product, 1)}
            >
              {product.productDescription}
              <Icon>arrow_forward_ios</Icon>
            </Button>
          );
        }, this)}
      </div>
    );
  }
}
