import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import ProductOverview from "./components/Product/ProductOverview";
import CreateProduct from "./components/CreateProduct/CreateProduct";
import RefundProduct from "./components/RefundProduct/RefundProduct";
import NotFound from "./components/NotFound/NotFound";
import UnauthenticatedRoute from "./components/Routing/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/Routing/AuthenticatedRoute";
import AppliedRoute from "./components/Routing/AppliedRoute";

export default ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute
      path="/product-overview"
      exact
      component={ProductOverview}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/createproduct"
      exact
      component={CreateProduct}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/refundproduct"
      exact
      component={RefundProduct}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
