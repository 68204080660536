import React, { Component } from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
  Icon,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

import COUNTRIES from "../../data/countries";

export default class ProductInfo extends Component {
  constructor() {
    super();
    this.state = {
      Price: "",
      Email: "",
      Reference: "",
      DepartmentCode: "",
      Salutation: "",
      Country: "",
      LastName: "",
      Inserts: "",
      Initials: "",
      Postcode: "",
      HouseNo: "",
      Residence: "",
      Street: "",
      ChipCardExpirationDate: "",
      ChipCardNumber: "",
      errors: null
    };
  }

  componentDidMount() {
    this.setState({ errors: null });
    this.setState({
      ChipCardExpirationDate: this.props.props.ChipCardExpirationDate
    });
    this.setState({ ChipCardNumber: this.props.props.ChipCardNumber });
    this.setState({ Price: this.props.props.Price });
  }

  handleChange = event => {
    this.setState({ errors: null });
    this.setState({ [event.target.name]: event.target.value });
    this.props.onUpdateStepperState(this.state, 3);
  };

  isFormValid = () => {
    this.checkFormValidation();
    if (this.state.errors !== null) {
      return false;
    }

    return true;
  };

  checkFormValidation = () => {
    if (!this.state.Price) {
      this.setState({ errors: { Price: true } });
    }
    if (!this.state.Email) {
      this.setState({ errors: { Email: true } });
    }
    if (!this.state.Country) {
      this.setState({ errors: { Country: true } });
    }
    if (!this.state.LastName) {
      this.setState({ errors: { LastName: true } });
    }
    if (!this.state.Initials) {
      this.setState({ errors: { Initials: true } });
    }
    if (!this.state.Postcode) {
      this.setState({ errors: { Postcode: true } });
    }
    if (!this.state.HouseNo) {
      this.setState({ errors: { HouseNo: true } });
    }
    if (!this.state.ChipCardNumber) {
      this.setState({ errors: { ChipCardNumber: true } });
    }
    if (!this.state.Salutation) {
      this.setState({ errors: { Salutation: true } });
    }
  };

  render() {
    return (
      <React.Fragment>
        <FormControl required className="input-form-control">
          <TextField
            label="OV-chipkaartnummer"
            value={this.state.ChipCardNumber}
            required
            disabled
            inputProps={{
              name: "ChipCardNumber",
              id: "ChipCardNumber"
            }}
          />
          <Tooltip
            className="right-tooltip"
            title="Vul hier de 16 cijfers in van de OV-chipkaart waar u het product op wilt zetten. De juiste prijs wordt berekend aan de hand van uw OV-chipkaartnummer. U ziet het definitieve tarief als u klikt op ‘bestellen’ of ‘plaats in winkelwagen’. "
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormControl className="input-form-control">
            <DatePicker
              label="Verloopdatum OV-chipkaart"
              value={this.state.ChipCardExpirationDate}
              animateYearScrolling
              format="MM/dd/yyyy"
              disabled
            />
          </FormControl>
        </MuiPickersUtilsProvider>
        <TextField
          label="Prij"
          value={this.state.Price}
          className="input-form-control"
          inputProps={{
            name: "Price",
            id: "Price"
          }}
          disabled
        />
        <FormControl required className="input-form-control">
          <TextField
            required
            label="E-mailadres kaarthouder"
            value={this.state.Email}
            type="email"
            inputProps={{
              name: "Email",
              id: "Email"
            }}
            onChange={e => this.handleChange(e)}
          />
          <Tooltip
            className="right-tooltip"
            title="Volgens contractafspraken kan hier een standaard emailadres van de contracthouder staan. In andere gevallen wordt het emailadres ingevuld van de kaarthouder (indien bekend). "
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
          {this.state.errors && this.state.errors.Email && (
            <FormHelperText className="error">Field is required</FormHelperText>
          )}
        </FormControl>
        <div className="info-container">
          <h3>Referentie</h3>
          <p>
            U kunt hier uw referentie (bijv. personeelsnummer) en afdeling
            opgeven.
          </p>
        </div>
        <FormControl className="input-form-control">
          <TextField
            label="Referentie"
            value={this.state.Reference}
            inputProps={{
              name: "Reference",
              id: "Reference"
            }}
            onChange={e => this.handleChange(e)}
          />
          <Tooltip
            className="right-tooltip"
            title="U kunt hier uw referentie(bijv. personeelsnummer) en afdeling opgeven. Bijvoorbeeld een personeels- of scholierennummer."
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
        </FormControl>
        <FormControl className="input-form-control">
          <TextField
            label="Afdelingscode"
            value={this.state.DepartmentCode}
            inputProps={{
              name: "DepartmentCode",
              id: "DepartmentCode"
            }}
            onChange={e => this.handleChange(e)}
          />
          <Tooltip
            className="right-tooltip"
            title="voer hier (optioneel) een afdelingscode in."
          >
            <Icon color="primary">help</Icon>
          </Tooltip>
        </FormControl>
        <div className="info-container">
          <h3>Gegevens kaarthouder</h3>
          <p>Vul hieronder de gegevens van de kaarthouder in.</p>
        </div>
        <FormControl required className="input-form-control">
          <label className="active" htmlFor="Salutation">
            Aanhef
          </label>
          <RadioGroup
            className="flex-row"
            required
            name="Salutation"
            value={this.state.Salutation}
            onChange={e => this.handleChange(e)}
          >
            <FormControlLabel value="0" control={<Radio />} label="Mevr." />
            <FormControlLabel value="1" control={<Radio />} label="Dhr." />
          </RadioGroup>
          {this.state.errors && this.state.errors.Salutation && (
            <FormHelperText className="error">Field is required</FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Voorletters"
            value={this.state.Initials}
            inputProps={{
              name: "Initials",
              id: "Initials"
            }}
            onChange={e => this.handleChange(e)}
          />
          {this.state.errors && this.state.errors.Initials && (
            <FormHelperText className="error">
              Geef uw voorletters op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            label="Tussenvoegsels"
            value={this.state.Inserts}
            inputProps={{
              name: "Inserts",
              id: "LasInsertstName"
            }}
            onChange={e => this.handleChange(e)}
          />
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Achternaam"
            value={this.state.LastName}
            inputProps={{
              name: "LastName",
              id: "LastName"
            }}
            onChange={e => this.handleChange(e)}
          />
          {this.state.errors && this.state.errors.LastName && (
            <FormHelperText className="error">
              Geef uw achternaam op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <InputLabel htmlFor="Country">Land</InputLabel>
          <Select
            inputProps={{
              name: "Country",
              id: "Country"
            }}
            value={this.state.Country | COUNTRIES[0]}
            onChange={e => this.handleChange(e)}
          >
            {Object.keys(COUNTRIES).map(x => (
              <MenuItem key={x} value={x}>
                {COUNTRIES[x]}
              </MenuItem>
            ))}
          </Select>
          {this.state.errors && this.state.errors.Country && (
            <FormHelperText className="error">
              Geef uw achternaam op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Postcode"
            value={this.state.Postcode}
            inputProps={{
              name: "Postcode",
              id: "Postcode"
            }}
            onChange={e => this.handleChange(e)}
          />
          {this.state.errors && this.state.errors.Postcode && (
            <FormHelperText className="error">
              Geef uw postcode op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Huisnummer"
            value={this.state.HouseNo}
            inputProps={{
              name: "HouseNo",
              id: "HouseNo"
            }}
            onChange={e => this.handleChange(e)}
          />
          {this.state.errors && this.state.errors.HouseNo && (
            <FormHelperText className="error">
              Geef uw huisnummer op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Straat"
            value={this.state.Street}
            inputProps={{
              name: "Street",
              id: "Street"
            }}
            disabled
          />
          {this.state.errors && this.state.errors.Street && (
            <FormHelperText className="error">
              Geef uw Straat op.
            </FormHelperText>
          )}
        </FormControl>
        <FormControl required className="input-form-control">
          <TextField
            required
            label="Woonplaats"
            value={this.state.Residence}
            inputProps={{
              name: "Residence",
              id: "Residence"
            }}
            onChange={e => this.handleChange(e)}
            disabled
          />
          {this.state.errors && this.state.errors.Residence && (
            <FormHelperText className="error">
              Geef uw Woonplaats op.
            </FormHelperText>
          )}
        </FormControl>
      </React.Fragment>
    );
  }
}
