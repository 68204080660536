import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Fetcher from "../../Fetcher";
import "./ProductOverview.css";

export class ProductOverview extends Component {
  constructor() {
    super();
    this.state = {
      products: []
    };
    this.productCellTemplate = this.productCellTemplate.bind(this);
    this.cardHolderCellTemplate = this.cardHolderCellTemplate.bind(this);
    this.dateCellTemplate = this.dateCellTemplate.bind(this);
    this.priceCellTemplate = this.priceCellTemplate.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
  }

  productCellTemplate(rowData, column) {
    let moreProdDescription = "";
    if (rowData.ZoneCenter) {
      moreProdDescription += "," + rowData.ZoneCenter;
    }
    if (rowData.ZoneRadius) {
      moreProdDescription += "," + rowData.ZoneRadius;
    }
    return (
      <div>
        <span>{rowData.productDescription + moreProdDescription}</span>
      </div>
    );
  }

  cardHolderCellTemplate(rowData, column) {
    return (
      <div>
        <div>{rowData.cardHolder.surname}</div>
        <div>{rowData.cardHolder.initials}</div>
      </div>
    );
  }

  dateCellTemplate(rowData, column) {
    const dateValue = column.value[0][column.field];
    return (
      <div>
        <span>{new Intl.DateTimeFormat("nl").format(new Date(dateValue))}</span>
      </div>
    );
  }

  priceCellTemplate(rowData, column) {
    const price = new Intl.NumberFormat("nl", {
      style: "currency",
      currency: "EUR"
    }).format(rowData.price);
    return <div>{price}</div>;
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          onClick={() => this.handleRefundProduct(rowData)}
          color="primary"
          variant="contained"
          disabled={rowData.RefundPossible}
        >
          Stopzetten
        </Button>
      </div>
    );
  }

  componentDidMount() {
    this.props.isLoadingMode(true);
    Fetcher.fetchAmyyon("contracts/234234/products/", "GET")
      .then(response => response.json())
      .then(json => {
        this.setState({ products: json });
        this.props.isLoadingMode(false);
      });
  }

  handleGetCatalogs = () => {
    this.props.history.push("/createproduct");
  };

  handleRefundProduct = cardProduct => {
    this.props.history.push({
      pathname: "/refundproduct",
      state: {
        cardProduct: cardProduct
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="table-container">
          <h1>Producten</h1>
          <Button
            color="primary"
            onClick={() => this.handleGetCatalogs()}
            variant="contained"
            className="new-product-button"
          >
            Bestel nieuw product
            <Icon>arrow_forward_ios</Icon>
          </Button>
          <p>
            In dit overzicht vindt u alle producten die onder bovenstaand
            contract besteld zijn. Zowel de producten die op dit moment actief
            zijn, als producten die al zijn beëindigd. U kunt filteren op
            verschillende rubrieken. Met de knop “Stopzetten” kunt u lopende
            producten beëindigen.
          </p>
          <p>Met ‘bestel nieuw product’ kunt u een nieuw product aanvragen.</p>

          <div className="content-section implementation">
            <DataTable value={this.state.products}>
              <Column
                field="engravedCardId"
                header="Chipkaartnummer"
                sortable={true}
              />
              <Column
                field="Kaarthouder"
                header="Kaarthouder"
                sortable={true}
                body={this.cardHolderCellTemplate}
              />
              <Column
                field="Product"
                header="Product"
                sortable={true}
                body={this.productCellTemplate}
              />
              <Column
                field="productStatus"
                header="ProdStatus"
                sortable={true}
                style={{ textAlign: "center", width: "7em" }}
              />
              <Column
                field="productDistributionStatus"
                header="Distributiestatus"
                sortable={true}
              />
              <Column
                field="startDate"
                header="Begin"
                sortable={true}
                body={this.dateCellTemplate}
                style={{ textAlign: "center", width: "8em" }}
              />
              <Column
                field="enddate"
                header="Einde"
                sortable={true}
                body={this.dateCellTemplate}
                style={{ textAlign: "center", width: "8em" }}
              />
              <Column
                field="price"
                header="Prijs"
                sortable={true}
                body={this.priceCellTemplate}
                style={{ textAlign: "center", width: "6em" }}
              />
              <Column
                body={this.actionTemplate}
                style={{ textAlign: "center", width: "11em" }}
              />
            </DataTable>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductOverview;
