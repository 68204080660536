import React, { Component } from "react";
import PropTypes from "prop-types";
import DateAndEmail from "../RefundProduct/DateAndEmail";
import Confirmation from "../RefundProduct/Confirmation";
import {
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography
} from "@material-ui/core";

const styles = theme => ({
  root: {
    width: "90%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class RefundProduct extends Component {
  static propTypes = {
    classes: PropTypes.object
  };

  state = {
    activeStep: 0,
    stepperState: {}
  };

  handleUpdateStepper = (e, id) => {
    const key = id !== undefined ? id : e.target.id;
    const value = id !== undefined ? e : e.target.value;

    this.setState(state => {
      const stepperState = state.stepperState;
      stepperState[key] = value;
      return {
        stepperState
      };
    });
  };

  getStepContent = step => {
    let cardProduct = this.props.location.state.cardProduct;
    switch (step) {
      case 0:
        return (
          <DateAndEmail
            cardProduct={cardProduct}
            stepperState={this.state.stepperState}
            onUpdateStepperState={this.handleUpdateStepper.bind(this)}
          />
        );
      case 1:
        return (
          <Confirmation
            cardProduct={cardProduct}
            stepperState={this.state.stepperState}
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  render() {
    const { classes } = this.props;
    const steps = ["Date and email", "Confirmation"];
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&quot;re finished
              </Typography>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {this.getStepContent(activeStep)}
              </Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Terug
                </Button>
                <Button
                  variant="raised"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1
                    ? "Bevestigen"
                    : "Volgende stap"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RefundProduct);
