import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

export default class DateAndEmail extends Component {
  componentDidMount() {
    const isPlaced = this.props.cardProduct.productDistributionStatus === 4;
    let desiredTerminationDate = !isPlaced
      ? new Date(this.props.cardProduct.firstRefundDate)
      : this.props.stepperState.date;

    this.props.onUpdateStepperState(desiredTerminationDate, "date");
  }

  render() {
    const isPlaced = this.props.cardProduct.productDistributionStatus === 4; // isGeplaatst
    return (
      <div className="page-container">
        <h1>Stopzetten</h1>
        <p>
          <b>Op welke datum wilt u de beeindiging laten ingaan?</b>
        </p>
        <div>Gewenste beeindigingsdatum</div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            animateYearScrolling
            id="date"
            format="dd-MM-yyyy"
            value={this.props.stepperState.date}
            disabled={!isPlaced}
            onChange={e => this.props.onUpdateStepperState(e, "date")}
          />
        </MuiPickersUtilsProvider>
        <br />
        <div>Zakelijk e-mailadres</div>
        <TextField
          id="mail"
          type="email"
          defaultValue={this.props.stepperState.mail}
          onBlur={e => this.props.onUpdateStepperState(e)}
        />
      </div>
    );
  }
}
