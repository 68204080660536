import React, { Component } from "react";

export default class Preview extends Component {
  constructor() {
    super();
    this.state = {
      ProductName: null,
      ProductId: null,
      CenterZone: null,
      StarValue: null,
      StartDate: null,
      EndDate: null,
      Price: null,
      Email: null,
      Reference: null,
      DepartmentCode: null,
      Salutation: null,
      Country: null,
      LastName: null,
      Inserts: null,
      Initials: null,
      Postcode: null,
      HouseNo: null,
      Residence: null,
      Street: null,
      ChipCardNumber: null
    };
  }

  componentDidMount() {
    this.setState({ ProductName: this.props.entireInfoPreview.ProductName });
    this.setState({ ProductId: this.props.entireInfoPreview.ProductId });
    this.setState({ CenterZone: this.props.entireInfoPreview.CenterZone });
    this.setState({ StarValue: this.props.entireInfoPreview.StarValue });
    this.setState({
      StartDate: new Date(this.props.entireInfoPreview.StartDate).toDateString()
    });
    this.setState({
      EndDate: new Date(this.props.entireInfoPreview.EndDate).toDateString()
    });
    this.setState({ Price: this.props.entireInfoPreview.Price });
    this.setState({ Email: this.props.entireInfoPreview.Email });
    this.setState({ Reference: this.props.entireInfoPreview.Reference });
    this.setState({
      DepartmentCode: this.props.entireInfoPreview.DepartmentCode
    });
    this.setState({ Salutation: this.props.entireInfoPreview.Salutation });
    this.setState({ Country: this.props.entireInfoPreview.Country });
    this.setState({ LastName: this.props.entireInfoPreview.LastName });
    this.setState({ Inserts: this.props.entireInfoPreview.Inserts });
    this.setState({ Initials: this.props.entireInfoPreview.Initials });
    this.setState({ Postcode: this.props.entireInfoPreview.Postcode });
    this.setState({ HouseNo: this.props.entireInfoPreview.HouseNo });
    this.setState({ Residence: this.props.entireInfoPreview.Residence });
    this.setState({ Street: this.props.entireInfoPreview.Street });
    this.setState({
      ChipCardNumber: this.props.entireInfoPreview.ChipCardNumber
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-container">
          <h2>Uw productgegevens</h2>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Product</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.ProductName}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Centrumzone</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.CenterZone}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Sterwaarde</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.StarValue}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Ingangsdatum</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.StartDate}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Einddatum</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.EndDate}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              OV-chipkaartnummer
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.ChipCardNumber}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Prijs</div>
            <div className="col-sm-3 col-md-3 col-lg-3">{this.state.Price}</div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Referentie</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Reference}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Afdeling</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.DepartmentCode}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Geslacht</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.StarValue}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Voorletters</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Initials}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              Tussenvoegsels
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Inserts}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Achternaam</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.LastName}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Land</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Country}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Postcode</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Postcode}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Huisnummer</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.HouseNo}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Staart</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Street}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">Woonplaats</div>
            <div className="col-sm-3 col-md-3 col-lg-3">
              {this.state.Residence}
            </div>
          </div>
          <div className="row">
            <div className="label col-sm-3 col-md-3 col-lg-3">
              E-mailadres kaarthouder
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">{this.state.Email}</div>
          </div>
        </div>
        <div className="info-container">
          <h3>
            Let op: als u klikt op 'bestellen' gaat u akkoord met de
            (product-)voorwaarden zoals die zijn gedefinieerd in het met uw
            bedrijf/organisatie afgesloten contract
          </h3>
        </div>
      </React.Fragment>
    );
  }
}
