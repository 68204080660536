import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Fetcher from "../../Fetcher";
import ListCatalogs from "./ListCatalogs";

export default class Catalogs extends Component {
  constructor() {
    super();
    this.state = {
      catalog: []
    };
  }
  componentDidMount() {
    this.props.props.isLoadingMode(true);
    Fetcher.fetchAmyyon("catalog/GetCatalog", "GET")
      .then(response => response.json())
      .then(
        response => {
          let catalog = response.products;
          this.setState({ catalog });
          this.props.props.isLoadingMode(false);
        },
        error => {
          this.props.props.history.push("/login");
          this.props.props.isLoadingMode(false);
        }
      );
  }

  handleBackFromCatalog = () => {
    this.props.props.history.push("/product-overview");
  };

  render() {
    return (
      <React.Fragment>
        <div className="info-container">
          <h1>Producten</h1>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleBackFromCatalog()}
            className="back-button"
          >
            <Icon>arrow_back_ios</Icon>
            Terug naar statusoverzicht
          </Button>
          <p>
            Kies hieronder een product. Klik op de pijlknop voor meer informatie
            of om het product direct te bestellen.
          </p>
        </div>
        <ListCatalogs
          value={this.state.catalog}
          clickCatalog={this.props.onUpdateStepperState}
        />
      </React.Fragment>
    );
  }
}
