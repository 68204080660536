import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import config from "./config";
import Auth from "./AWSCognito/Auth";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

Auth.configure({
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolWebClientId: config.cognito.APP_CLIENT_ID
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
