let dataMemory = {};

class MemoryStorage {
  static setItem(key, value) {
    dataMemory[key] = value;
    return dataMemory[key];
  }

  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
  }

  static removeItem(key) {
    return delete dataMemory[key];
  }

  static clear() {
    dataMemory = {};
    return dataMemory;
  }
}

export default class StorageHelper {
  constructor() {
    try {
      this.storageWindow = window.localStorage;
      this.storageWindow.setItem("aws.cognito.test-ls", 1);
      this.storageWindow.removeItem("aws.cognito.test-ls");
    } catch (exception) {
      this.storageWindow = MemoryStorage;
    }
  }

  getStorage() {
    return this.storageWindow;
  }
}
