import CognitoUser from "./CognitoUser";
import StorageHelper from "./StorageHelper";

export default class CognitoUserPool {
  constructor(data) {
    const { UserPoolId, ClientId, AdvancedSecurityDataCollectionFlag } =
      data || {};
    if (!UserPoolId || !ClientId) {
      throw new Error("Both UserPoolId and ClientId are required.");
    }
    if (!/^[\w-]+_.+$/.test(UserPoolId)) {
      throw new Error("Invalid UserPoolId format.");
    }

    this.userPoolId = UserPoolId;
    this.clientId = ClientId;

    this.advancedSecurityDataCollectionFlag =
      AdvancedSecurityDataCollectionFlag !== false;

    this.storage = data.Storage || new StorageHelper().getStorage();
  }
  getUserPoolId() {
    return this.userPoolId;
  }
  getClientId() {
    return this.clientId;
  }

  signUp(username, password, userAttributes, validationData) {
    const jsonReq = {
      ClientId: this.clientId,
      Username: username,
      Password: password,
      UserAttributes: userAttributes,
      ValidationData: validationData
    };
    if (this.getUserContextData(username)) {
      jsonReq.UserContextData = this.getUserContextData(username);
    }
    const cognitoUser = {
      Username: username,
      Pool: this,
      Storage: this.storage
    };

    new CognitoUser(cognitoUser);
  }

  getCurrentUser() {
    const lastUserKey = `CognitoIdentityServiceProvider.${
      this.clientId
    }.LastAuthUser`;

    const lastAuthUser = this.storage.getItem(lastUserKey);
    if (lastAuthUser) {
      const cognitoUser = {
        Username: lastAuthUser,
        Pool: this,
        Storage: this.storage
      };

      return new CognitoUser(cognitoUser);
    }

    return null;
  }

  getUserContextData(username) {
    if (typeof AmazonCognitoAdvancedSecurityData === "undefined") {
      return undefined;
    }
    /* eslint-disable */
    const amazonCognitoAdvancedSecurityDataConst = AmazonCognitoAdvancedSecurityData;
    /* eslint-enable */

    if (this.advancedSecurityDataCollectionFlag) {
      const advancedSecurityData = amazonCognitoAdvancedSecurityDataConst.getData(
        username,
        this.userPoolId,
        this.clientId
      );
      if (advancedSecurityData) {
        const userContextData = {
          EncodedData: advancedSecurityData
        };
        return userContextData;
      }
    }
    return {};
  }
}
